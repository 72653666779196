import { useEffect } from "react";

function Push() {
    useEffect(() => {
        const sender = {
            action: 'request_push_notifications'
        };
        if (window.hasOwnProperty('ReactNativeWebView')) {
            window.ReactNativeWebView.postMessage(JSON.stringify(sender));
        }
    }, []);

    return (
        <div className="App-Component component-wrapper">
            <h1 className="text-2xl mb-4">Push</h1>
            <h3 className="text-xl mb-4">Let's see if it works</h3>
            <div className="text-center">
                <p>
                    Just for push
                </p>
            </div>
        </div>
    );
}

export default Push;
