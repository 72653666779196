import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiErrorCircle } from 'react-icons/bi';
import firebaseConfig from './firebase_config';
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import moment from 'moment';
import parseSmartDate from './lib/common/parseSmartDate';
import describeFutureDate from './lib/common/describeFutureDate';
import getSpecialDate from './lib/common/getSpecialDate';
import getDurationInSeconds from './lib/common/getDurationInSeconds';
import SignInWithApple from './SignInWithApple';

import './DashboardScreen.css';

const PLAID_LOGIN_ERRORS = ['ITEM_LOGIN_REQUIRED', 'PENDING_EXPIRATION'];
const PLAID_DEVELOPMENT_DEPRECATED = ['DEVELOPMENT_DEPRECATED'];

function Dashboard() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [trialCount, setTrialCount] = useState(null);
    const [trials, setTrials] = useState([]);
    const [subsCount, setSubsCount] = useState(null);
    const [subs, setSubs] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [intervalId, setIntervalId] = useState(null);

    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }

    const db = firebase.firestore();
    const currentUser = firebase.auth().currentUser;

    const checkAndChangeUser = async (newEmail) => {
        try {
            setLoading(true);
            const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
            if (!!idTokenResult.claims.admin) {
                window.localStorage.setItem("admin", true);
                window.localStorage.setItem("email", newEmail);

                fetch(process.env.REACT_APP_CANCELLY_API_URL + "/auth/impersonate", {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${idTokenResult.token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userEmail: newEmail,
                    })
                })
                    .then(res => res.json())
                    .then(async res => {
                        await firebase.auth().signInWithCustomToken(res.token);
                        navigate("/");
                    })
                    .catch(err => {
                        console.error('Error occurred', err);
                    });
            } else {
                window.localStorage.removeItem("admin");
                window.localStorage.removeItem("email");
            }
        } catch (error) {
            console.log("Could not add keydown event listener (Ctrl+K)");
            window.localStorage.removeItem("admin");
            window.localStorage.removeItem("email");
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
            event.preventDefault();
            const usersEmail = prompt("Please enter user's email: ");
            checkAndChangeUser(usersEmail);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        var NotificationIsSupported = !!(window.Notification || window.webkitNotifications || navigator.mozNotification)
        if (NotificationIsSupported) {
            // JavaScript to toggle the tooltip animation
            const requestNotification = localStorage.getItem("notificationTooltip");
            // const notificationPermission = localStorage.getItem("notifications_permission");
            if (requestNotification !== "hide") {
                const tooltipElement = document.querySelector('.App-Tooltip');
                tooltipElement.classList.add('active');
            }
        }
    }, []);

    const requestNotification = () => {
        var notification = window.Notification || window.webkitNotifications || navigator.mozNotification;
        notification.requestPermission()
            .then((result) => {
                console.log('Push Notification Result', result);
                localStorage.setItem("notifications_permission", result);
                if (result === 'granted') {
                    // get device token and save to db
                    try {
                        const answer = window.safari.pushNotification.permission();
                        console.log('answer', answer);
                    } catch (err) {
                        console.error('Error in window.safari.pushNotification.permission()', err);
                    }
                }
                hideTooltip();
            }).catch(() => {
                hideTooltip();
            });
    }

    const hideTooltip = () => {
        localStorage.setItem("notificationTooltip", "hide");
        const tooltipElement = document.querySelector('.App-Tooltip');
        tooltipElement.classList.remove('active');
    }

    const getTrialsCountForCurrentUser = async () => {
        try {
            if (currentUser) {
                const collectionRef = db.collection('trials'); // Replace with your collection name
                const querySnapshot = await collectionRef.where('uid', '==', currentUser.uid).get();

                let counter = 0;
                const today = new Date();
                const todayTimestamp = Math.floor(today.getTime() / 1000);
                today.setDate(today.getDate() + 1);
                const tomorrowTimestamp = Math.floor(today.getTime() / 1000);

                querySnapshot.forEach(doc => {
                    const { created_ts, duration } = doc.data();
                    const expirationTimestamp = created_ts.seconds + getDurationInSeconds(duration);
                    if (expirationTimestamp >= tomorrowTimestamp) {
                        counter++;
                    }
                });

                setTrialCount(counter);
            }
        } catch (error) {
            console.error('Error fetching document count:', error);
        }
    };

    const getTrialsForCurrentUser = async () => {
        let data = [];
        try {
            if (currentUser) {
                const collectionRef = db.collection('trials');
                const querySnapshot = await collectionRef.where('uid', '==', currentUser.uid)
                    .orderBy('created_ts', 'desc')
                    .limit(50)
                    .get();
                querySnapshot.forEach(doc => {
                    data.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
            }
        } catch (error) {
            console.error('Error fetching Firestore data:', error);
        }

        data = data.map(doc => {
            const newDoc = {
                current_date: moment.utc(doc.created_ts.seconds * 1000),
                future_date: parseSmartDate(doc.created_ts, doc.duration),
                ...doc
            };
            newDoc._date_diff = newDoc.future_date.diff(newDoc.current_date);
            return newDoc;
        });
        data = data.filter(doc => doc.future_date >= moment().add(1, "days"));
        data = data.sort((a, b) => a._date_diff - b._date_diff);
        if (data && data.length > 0) {
            data = data.slice(0, 3);
            setTrials(data || []);
        }
    };

    const getSubsCountForCurrentUser = async () => {
        if (currentUser) {
            const token = await currentUser.getIdToken();

            fetch(process.env.REACT_APP_CANCELLY_API_URL + '/plaid/count_subs', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then(res => res.json())
                .then((res) => {
                    if (res.hasOwnProperty('error')) {
                        console.log(res.error);
                    }
                    setSubsCount(res.count);
                })
                .catch((err) => {
                    console.error('Error fetching document count:', err);
                });
        }
    };

    const getSubsForCurrentUser = async () => {
        try {
            if (currentUser) {
                const token = await currentUser.getIdToken();

                fetch(process.env.REACT_APP_CANCELLY_API_URL + '/subs/list', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
                    .then(res => res.json())
                    .then((res) => {
                        if (res.hasOwnProperty('error')) {
                            console.log(res.error);
                        }

                        setSubsCount(res.subs.length);

                        let data = res.subs;

                        data = data.map(doc => {
                            const newDoc = {
                                current_date: moment.utc(doc.created_ts.seconds * 1000),
                                ...doc
                            };
                            return newDoc;
                        })
                        data = data.sort((a, b) => a.current_date.diff(b.current_date));
                        if (data && data.length > 0) {
                            setSubs(data);
                        }
                    })
                    .catch((err) => {
                        console.error('Error fetching document count:', err);
                    });
            }
        } catch (error) {
            console.error('Error fetching Firestore data:', error);
        }
    };

    const getAccountsForCurrentUser = async () => {
        if (currentUser) {
            const token = await currentUser.getIdToken();

            fetch(process.env.REACT_APP_CANCELLY_API_URL + '/plaid/bank_accounts', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then(res => res.json())
                .then((res) => {
                    setBankAccounts(res.institutions || []);
                })
                .catch((err) => {
                    console.error('Error fetching documents:', err);
                });
        }
    };

    useEffect(() => {
        getAccountsForCurrentUser();
    }, []);

    useEffect(() => {
        getTrialsCountForCurrentUser();
        //getSubsCountForCurrentUser();
        getSubsForCurrentUser();
        getTrialsForCurrentUser();
    }, [currentUser]);

    useEffect(() => {
        const handleButtonClick = () => {
            if ("vibrate" in navigator) {
                navigator.vibrate(100);
            }
        }

        const buttons = document.querySelectorAll('button');
        buttons.forEach(button => {
            button.addEventListener('click', handleButtonClick);
        });

        return () => {
            buttons.forEach(button => {
                button.removeEventListener('click', handleButtonClick);
            });
        }
    }, []);

    const handleTrialRemove = async (event, trial) => {
        const targetElement = event.target;

        const doDelete = async () => {
            try {
                if (currentUser) {
                    const documentRef = db.collection('trials').doc(trial.id);
                    const docSnapshot = await documentRef.get();

                    if (docSnapshot.exists && docSnapshot.data().uid === currentUser.uid) {
                        await documentRef.delete();
                        await getTrialsForCurrentUser();
                    } else {
                        console.log("Document doesn't belong to the current user.");
                    }
                }
            } catch (error) {
                alert('Error deleting document');
                console.error('Error deleting document', error);
            }
        };

        switch (targetElement.innerText) {
            case 'Delete':
                setIntervalId(setInterval(doDelete, 5000));
                targetElement.innerText = 'Undo';
                break;
            case 'Undo':
                clearInterval(intervalId);
                targetElement.innerText = 'Delete';
                break;
            default:
                break;
        }
    }

    const fixBank = async (event) => {
        if (!loading && window.confirm("Let's fix that?")) {
            if (currentUser) {
                setLoading(true);
                setTimeout(function () {
                    setLoading(false);
                }, 30 * 1000); // 30 seconds timouet for loader

                const prevButtonText = event.target.innerText;
                event.target.innerText = 'Loading...';

                console.log('User email:', currentUser.email);

                const itemId = event.currentTarget.getAttribute('data-item-id');

                const token = await currentUser.getIdToken();

                fetch(process.env.REACT_APP_CANCELLY_API_URL + '/plaid/refresh_token', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: currentUser.email,
                        itemId: itemId,
                    }),
                })
                    .then(res => res.json())
                    .then((res) => {
                        const handler = window.Plaid.create({
                            token: res.linkToken,
                            onSuccess: (publicToken, metadata) => {
                                fetch(process.env.REACT_APP_CANCELLY_API_URL + '/exchange_public_token', {
                                    method: 'POST',
                                    headers: {
                                        'Authorization': `Bearer ${token}`,
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        email: currentUser.email,
                                        linkToken: res.linkToken,
                                        publicToken,
                                        oldAccessToken: res.oldAccessToken,
                                    }),
                                }).then(async () => {
                                    getAccountsForCurrentUser();
                                }).catch(err => {
                                    setLoading(false);
                                    event.target.innerText = prevButtonText;
                                });
                            },
                            onExit: (err, metadata) => {
                                setLoading(false);
                                event.target.innerText = prevButtonText;
                                alert("Error Occurred. Please try again later or contact support@cancelly.ca");
                            },
                            onEvent: (eventName, metadata) => {
                                console.log('Plaid onEvent', eventName, metadata);
                                setLoading(false);
                                event.target.innerText = prevButtonText;
                            },
                        });

                        handler.open();
                    })
                    .catch((err) => {
                        console.error('Error fetching documents:', err);
                        setLoading(false);
                        event.target.innerText = prevButtonText;
                    });

                // event.target.innerText = text;
            }
        }
    }

    const refreshManual = async (institution) => {
        const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
        const sender = {
            action: 'institution_connect',
            body: {
                token: idTokenResult.token,
                institution,
            }
        };
        if (window.hasOwnProperty('chrome') && chrome.hasOwnProperty('runtime') && chrome.runtime.hasOwnProperty('sendMessage')) {
            console.log('Chrome Runtime is available');
            try {
                window.chrome.runtime.sendMessage("bdehkmgfjppeagefkipigfmlnginebkd", sender); /* global chrome */
                window.chrome.runtime.sendMessage("jlmgalhiiblhpgkemdoajofnbmhkoplg", sender); /* global chrome */
            } catch (err) {
                console.log('Error sending message to chrome extension', err);
                alert('Requires Cancelly App or Chrome Extension');
            }
        } else if (window.hasOwnProperty('ReactNativeWebView') && window.ReactNativeWebView.hasOwnProperty('postMessage')) {
            console.log('React Native runtime is available');
            try {
                window.ReactNativeWebView.postMessage(JSON.stringify(sender));
            } catch (err) {
                console.log('Error sending message to native app', err);
                alert('Requires Cancelly App or Chrome Extension');
            }
        } else {
            console.error('window.ReactNativeWebView.postMessage or chrome.runtime.sendMessage does not exists');
            alert('Requires Cancelly App or Chrome Extension');
        }
    };

    const disconnectBank = async (event) => {
        if (!loading && window.confirm("Are you sure you want to revoke access to this bank?")) {
            const itemId = event.currentTarget.getAttribute('data-item-id');
            const name = event.currentTarget.getAttribute('data-item-name');

            navigate("/disconnect", {
                state: {
                    name,
                    itemId,
                }
            });
        }
    }

    return (
        <div className="text-white w-[600px] lg:w-full m-auto">
            <div className="App-Tooltip p-2 z-50">
                <p>Allow Notifications? <button onClick={requestNotification}>Yes</button> <button onClick={hideTooltip}>No</button></p>
                <p>Don't miss out on new subscription alerts!</p>
            </div>
            {loading
                ? <img className="self-center inline" src="loader.gif" alt="Loading..." width="100" />
                : <>
                    {/* <h2 className="text-xl mt-4 px-4 font-bold select-none">Next review scheduled on <br />{getSpecialDate()}</h2> */}
                    <div className="text-left mt-4 ml-8 mr-8 mb-6 bg-white text-black rounded">
                        <h3 className="text-xl font-bold border-b-2 p-2 select-none">Bank Accounts</h3>
                        {bankAccounts.length > 0 ? <div>
                            <ol className="py-2 px-4 list-decimal ml-4">
                                {bankAccounts.map(account => {
                                    return <li key={account.id} className={(PLAID_LOGIN_ERRORS.includes(account.status) || PLAID_DEVELOPMENT_DEPRECATED.includes(account.status) || (!account.hasOwnProperty('item_id') && moment(account.latest_ts?.value) && moment(account?.latest_ts.value).diff(moment(), "days") < -5)) ? "mb-2 text-red-500 font-bold" : "mb-2 text-blue-600 font-bold"}>
                                        {PLAID_LOGIN_ERRORS.includes(account.status) ?
                                            <button data-item-id={account?.item_id}
                                                data-item-name={account.name}
                                                className="ml-2 flex flex-row m-0 p-0 leading-0"
                                                onClick={fixBank}>
                                                <span>{account.name}</span>
                                                <><BiErrorCircle className="ml-4 mt-1" />
                                                    <span className="ml-1">Connection broken, click here to fix</span></>
                                            </button> :
                                            <button data-item-id={account.item_id}
                                                data-item-name={account.name}
                                                className="ml-2 flex flex-row m-0 p-0 leading-0"
                                                onClick={account.type === 'cancelly_extension' ? () => refreshManual(account) : disconnectBank}
                                                title={account.type === 'cancelly_extension' ? "Click to bring fresh data" : "Click to disconnect bank"}>
                                                <p className="text-left">
                                                    {account.name} {account.type === 'cancelly_extension' && <span className="text-sm">- Updated {moment.duration(moment(account.latest_ts?.value).diff(moment())).humanize(true)}</span>}
                                                    {PLAID_DEVELOPMENT_DEPRECATED.includes(account.status) && ' (Old Version, Not Supported Anymore, Please disconnect and reconnect)'}
                                                </p>
                                            </button>}
                                    </li>;
                                })}
                            </ol>
                            <div className="flex ml-4 pb-2">
                                <button onClick={() => navigate("/connect")} className="bg-black text-white p-2 hover:text-slate-400">Connect Bank Account</button>
                                <SignInWithApple buttonText="Connect Apple Account"></SignInWithApple>
                            </div>
                        </div> : <div className="px-4 py-2">
                            <p>No bank accounts found</p>
                            <div className="flex">
                                <button onClick={() => navigate("/connect")} className="bg-black text-white p-2 mt-2 hover:text-slate-400">Connect Bank Account</button>
                                <SignInWithApple buttonText="Connect Apple Account"></SignInWithApple>
                            </div>
                        </div>
                        }
                    </div>
                    <div className="text-left mt-8 ml-8 mr-8 mb-6  text-black rounded">
                        <div className="flex">
                            <button className="w-1/2" onMouseUp={() => navigate("/subscriptions")}>
                                <div className="flex flex-col mr-2 p-2 bg-white rounded-2xl h-48 opacity-95 items-center text-center">
                                    <p className="text-2xl xs:text-lg relative z-0 select-none break-all">Subscriptions</p>
                                    {subsCount !== null
                                        ? <p className="text-9xl m-auto relative z-0 select-none">{subsCount}</p>
                                        : <div className="wave">
                                            <span class="dot"></span>
                                            <span class="dot"></span>
                                            <span class="dot"></span>
                                        </div>}
                                </div>
                            </button>
                            <div className="w-1/2">
                                <div className="flex flex-col mr-2 p-2 bg-white rounded-2xl h-48 opacity-95 items-center text-center">
                                    <p className="text-2xl xs:text-lg relative z-0 select-none break-all">Trials</p>
                                    {trialCount !== null
                                        ? <p className="text-9xl m-auto relative z-0 select-none break-all">{trialCount}</p>
                                        : <div className="wave">
                                            <span class="dot"></span>
                                            <span class="dot"></span>
                                            <span class="dot"></span>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-left mt-8 ml-8 mr-8 mb-6 bg-white text-black rounded">
                        <h3 className="text-xl font-bold border-b-2 p-2 select-none">Upcoming Trials Renewal</h3>
                        {trials.length > 0 ?
                            <ul className="py-2 px-4">
                                {trials.map(trial => {
                                    return <li key={trial.id} className="mb-4">
                                        <a href={trial.url} target="_blank" className="hover:text-[#523bff] block truncate">
                                            <img alt={trial.title} src={trial.icon} className="inline w-4 mr-2" />{trial.title}</a><span className="">({describeFutureDate(trial.future_date)})</span>
                                        <button className="bg-black text-white ml-1 px-1 text-xs" onClick={(e) => { handleTrialRemove(e, trial) }}>Delete</button>
                                    </li>;
                                })}
                            </ul>
                            :
                            <div className="px-4 py-2">
                                <b>Improve your experience by adding Cancelly to your Chrome.</b>
                                <ul style={{ listStyleType: 'circle', marginLeft: '20px' }}>
                                    <li>Add web pages to remind you to cancel your trials.</li>
                                    <li>It collects your website history to keep track of your spending.</li>
                                    <li>Manually add unsupported institutions data: Koho, MBNA Canada, etc</li>
                                </ul>
                                <button onClick={() => window.location = "https://chrome.google.com/webstore/detail/cancelly/bdehkmgfjppeagefkipigfmlnginebkd"} className="bg-black text-white p-2 mt-2 hover:text-slate-400 select-none">Add to Chrome</button>
                            </div>}
                    </div>
                </>}
        </div>
    );
}

export default Dashboard;
