import './style.css';

function DocsScreen() {
    return (
        // <div className="App-Component component-wrapper w-[600px] w-full m-auto mt-8">
        //     <h3 className="text-xl mb-4">Request data from your users with their consent</h3>
        //     <div className="text-justify mt-8">
        //         <p>Step 1: <a href="/business-profile" className="underline">Create a business profile</a></p>
        //         <p>Step 2: <a href="/api-keys" className="underline">Get API Keys</a></p>
        //         <p>Step 3: <a href="/api-keys" className="underline">Create a new request link to share with user</a></p>
        //         <div>

        //         </div>
        //         <p>Step 4: <a href="/api-keys" className="underline">Check for response</a></p>
        //     </div>
        // </div>

        <div className="p-6 lg:p-0 lg:mt-8">
            <p className="text-white">This documentation is work in progress</p>

            <div class="max-w-screen-xl px-4 mx-auto mt-8 lg:mt-12 lg:px-6 md:mt-16">
                <div class="grid grid-cols-12 gap-8 lg:grid-cols-1 lg:gap-1">
                    <div class="lg:text-center md:max-w-2xl md:mx-auto col-span-6 text-left">
                        <div class="text-sm font-semibold tracking-wide text-gray-400 uppercase lg:text-base text-sm xl:text-base">
                            Cancelly HTTPS API
                        </div>
                        <h2
                            class="mt-1 text-4xl font-extrabold leading-10 tracking-tight text-white lg:leading-none lg:text-6xl text-5xl xl:text-6xl">
                            GET /data/list-third-parties
                        </h2>
                        <p class="mt-3 text-base white lg:mt-5 lg:text-xl text-lg xl:text-xl text-white">
                            Get a list of third parties currently supported by Cancelly's API
                        </p>
                    </div>
                    <div class="relative mt-12 lg:mt-6 lg:max-w-lg lg:mx-auto max-w-none lg:w-full mx-0 col-span-6 flex items-center">
                        <svg
                            class="absolute top-0 origin-top transform scale-75 -translate-x-1/2 -translate-y-8 left-1/2 lg:scale-100 hidden"
                            width="640" height="784" fill="none" viewBox="0 0 640 784">
                            <defs>
                                <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20"
                                    patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"></rect>
                                </pattern>
                            </defs>
                            <rect y="72" width="640" height="640" class="text-gray-50" fill="currentColor"></rect>
                            <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"></rect>
                        </svg>
                        <div class="relative justify-end w-full flex">
                            <div
                                class="w-full justify-end inline-block overflow-x-scroll p-8 pr-8 text-base lg:text-sm leading-relaxed text-blue-400 bg-indigo-900 rounded-lg shadow-lg text-xm">
                                <pre class="text-left">
                                    <span class="text-blue-100">// Create a new request on your server</span>
                                    <br />
                                    {`fetch("https://cancelly.ca/data/list-third-parties", {
    headers: {
        "Content-Type": "application/json",
        "APIKEY": "Write your Cancelly API Key here",
        "SECRET": "Write your Cancelly API Secret here",
    }),
});`}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="max-w-screen-xl px-4 mx-auto mt-8 lg:mt-12 lg:px-6 md:mt-16">
                <div class="grid grid-cols-12 gap-8 lg:grid-cols-1 lg:gap-1">
                    <div class="lg:text-center md:max-w-2xl md:mx-auto col-span-6 text-left">
                        <div class="text-sm font-semibold tracking-wide text-gray-400 uppercase lg:text-base text-sm xl:text-base">
                            Cancelly HTTPS API
                        </div>
                        <h2
                            class="mt-1 text-4xl font-extrabold leading-10 tracking-tight text-white lg:leading-none lg:text-6xl text-5xl xl:text-6xl">
                            Response
                        </h2>
                        <p class="mt-3 text-base white lg:mt-5 lg:text-xl text-lg xl:text-xl text-white">
                            Cancelly response will include a status and data. Make sure status is ok and use data field to get list of supported third parties.
                        </p>
                        <p class="mt-3 text-base white lg:mt-5 lg:text-xl text-lg xl:text-xl text-white">
                            In case of an error, status will be <code className="lg:text-sm">{`{ status: error }`}</code> having an <code className="lg:text-sm">{`{ error: 'Description of the error' }`}</code> field.
                        </p>
                    </div>
                    <div class="relative mt-12 lg:mt-6 lg:max-w-lg lg:mx-auto max-w-none lg:w-full mx-0 col-span-6 flex items-center">
                        <svg
                            class="absolute top-0 origin-top transform scale-75 -translate-x-1/2 -translate-y-8 left-1/2 lg:scale-100 hidden"
                            width="640" height="784" fill="none" viewBox="0 0 640 784">
                            <defs>
                                <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20"
                                    patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"></rect>
                                </pattern>
                            </defs>
                            <rect y="72" width="640" height="640" class="text-gray-50" fill="currentColor"></rect>
                            <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"></rect>
                        </svg>
                        <div class="relative justify-end w-full flex">
                            <div
                                class="w-full justify-end inline-block overflow-x-scroll p-8 pr-8 text-base lg:text-sm leading-relaxed text-blue-400 bg-indigo-900 rounded-lg shadow-lg text-xm pr-16">
                                <pre class="text-left">
                                    {`{
    status: "ok"
    data: [
        { "id": "758n98gY5roU2PoFYo7m", "name": "SAAQClic" }
        { "id": "2Pols57JKc9OqpM5pduY", "name": "RevenuQuebec" }
    ]
}`}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="max-w-screen-xl px-4 mx-auto mt-8 lg:mt-12 lg:px-6 md:mt-16">
                <div class="grid grid-cols-12 gap-8 lg:grid-cols-1 lg:gap-1">
                    <div class="lg:text-center md:max-w-2xl md:mx-auto col-span-6 text-left">
                        <div class="text-sm font-semibold tracking-wide text-gray-400 uppercase lg:text-base text-sm xl:text-base">
                            Cancelly HTTPS API
                        </div>
                        <h2
                            class="mt-1 text-4xl font-extrabold leading-10 tracking-tight text-white lg:leading-none lg:text-6xl text-5xl xl:text-6xl">
                            Get /data/request
                        </h2>
                        <p class="mt-3 text-base white lg:mt-5 lg:text-xl text-lg xl:text-xl text-white">
                            Use this endpoint to create a unique url for your users to share their personal data with you. This could be from their financial institution, government online service or something else which Cancelly supports.
                        </p>
                    </div>
                    <div class="relative mt-12 lg:mt-6 lg:max-w-lg lg:mx-auto max-w-none lg:w-full mx-0 col-span-6 flex items-center">
                        <svg
                            class="absolute top-0 origin-top transform scale-75 -translate-x-1/2 -translate-y-8 left-1/2 lg:scale-100 hidden"
                            width="640" height="784" fill="none" viewBox="0 0 640 784">
                            <defs>
                                <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20"
                                    patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"></rect>
                                </pattern>
                            </defs>
                            <rect y="72" width="640" height="640" class="text-gray-50" fill="currentColor"></rect>
                            <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"></rect>
                        </svg>
                        <div class="relative justify-end w-full flex">
                            <div
                                class="w-full justify-end inline-block overflow-x-scroll p-8 pr-8 text-base lg:text-sm leading-relaxed text-blue-400 bg-indigo-900 rounded-lg shadow-lg text-xm">
                                <pre class="text-left">
                                    <span class="text-blue-100">// Create a new request on your server</span>
                                    <br />
                                    {`fetch("https://cancelly.ca/data/request", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        "APIKEY": "Write your Cancelly API Key here",
        "SECRET": "Write your Cancelly API Secret here",
    },
    body: JSON.stringify({
        thirdPartyName: "SAAQClic", // SAAQClic, Koho, MBNA
        redirectUrl: "https://www.riamuapp.com/callback", // method: GET
        webhookUrl: "https://www.riamuapp.com/callback" // method: POST
    }),
});`}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="max-w-screen-xl px-4 mx-auto mt-8 lg:mt-12 lg:px-6 md:mt-16">
                <div class="grid grid-cols-12 gap-8 lg:grid-cols-1 lg:gap-1">
                    <div class="lg:text-center md:max-w-2xl md:mx-auto col-span-6 text-left">
                        <div class="text-sm font-semibold tracking-wide text-gray-400 uppercase lg:text-base text-sm xl:text-base">
                            Cancelly HTTPS API
                        </div>
                        <h2
                            class="mt-1 text-4xl font-extrabold leading-10 tracking-tight text-white lg:leading-none lg:text-6xl text-5xl xl:text-6xl">
                            Response
                        </h2>
                        <p class="mt-3 text-base white lg:mt-5 lg:text-xl text-lg xl:text-xl text-white">
                            Cancelly response will include a status and url. Make sure status is ok and navigate your user to the given URL for them to continue sharing their data.
                        </p>
                        <p class="mt-3 text-base white lg:mt-5 lg:text-xl text-lg xl:text-xl text-white">
                            In case of an error, status will be <code className="lg:text-sm">{`{ status: error }`}</code> having an <code className="lg:text-sm">{`{ error: 'Description of the error' }`}</code> field.
                        </p>
                    </div>
                    <div class="relative mt-12 lg:mt-6 lg:max-w-lg lg:mx-auto max-w-none lg:w-full mx-0 col-span-6 flex items-center">
                        <svg
                            class="absolute top-0 origin-top transform scale-75 -translate-x-1/2 -translate-y-8 left-1/2 lg:scale-100 hidden"
                            width="640" height="784" fill="none" viewBox="0 0 640 784">
                            <defs>
                                <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20"
                                    patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"></rect>
                                </pattern>
                            </defs>
                            <rect y="72" width="640" height="640" class="text-gray-50" fill="currentColor"></rect>
                            <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"></rect>
                        </svg>
                        <div class="relative justify-end w-full flex">
                            <div
                                class="w-full justify-end inline-block overflow-x-scroll p-8 pr-8 text-base lg:text-sm leading-relaxed text-blue-400 bg-indigo-900 rounded-lg shadow-lg text-xm pr-16">
                                <pre class="text-left">
                                    <span class="text-blue-100">// Navigate your user to the given URL</span>
                                    <br />
                                    {`{
    status: "ok"
    url: "https://cancelly.ca/request-data?session=SESSION_ID"
}`}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocsScreen;
