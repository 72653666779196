function Why() {
    return (
        <div className="App-Component component-wrapper">
            <h1 className="text-2xl mb-4">Why do I see Cancelly in my logs?</h1>
            <h3 className="text-xl mb-4">Rest assured, we take security, privacy and transparency very seriously!</h3>
            <div className="text-center">
                <p>
                    Cancelly provides a way for its users to connect with third party apps and websites so they can sync their data easily and efficiently. If you see Cancelly in your log files, this means that Cancelly wants you to know that our users are also the users of your service and they have chosen to connect your website or app with Cancelly.
                </p>
            </div>
            <br />
            <h3 className="text-xl mb-4">Private, Secure and Transparent</h3>
            <div className="text-center">
                <p>
                    Cancelly stores all data in SOC2 compliant data storage provided by major company making sure it's secure and private. We are transparent with our customers, vendors and business partners. And we put our customers' privacy at the highest standards.
                </p>
            </div>
            <br />
            <h3 className="text-xl mb-4">Complaints? Send us an email!</h3>
            <div className="text-center">
                <p>
                    We have no abuse policy. The goal of integrations is to solely benefit the users and our customers. If you have any compliants, concerns or feedback, please email us at support@cancelly.ca
                </p>
                <br />
                <a href="/" className="border-solid border-gray border-[1px] py-2 px-8 bg-black">Visit Homepage</a>
            </div>
        </div>
    );
}

export default Why;
