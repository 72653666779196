function Support() {
    return (
        <div className="App-Component component-wrapper">
            <h1 className="text-2xl mb-4">Support</h1>
            <p>All support is made through the live chat provided by Cancelly. Please feel free to email us your feedback and concerns at support@cancelly.ca</p>
            <div className="text-center mt-5">
                <a href="/" className="border-solid border-gray border-[1px] py-2 px-8 bg-black">Visit Homepage</a>
            </div>
        </div>
    );
}

export default Support;